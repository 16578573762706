.new .progress {
  position: relative;
  margin-bottom: 1px;
}
.new .progress > .progress-type {
  position: absolute;
  left: 0px;
  font-weight: bold !important;
  font-size: 90%;
  padding: 3px 30px 2px 10px;
  color: #ffffff !important;
  background-color: rgba(25, 25, 25, 0.2);
}
.new .progress > .progress-completed {
  position: absolute;
  right: 0px;
  font-weight: bold;
  font-size: 90%;
  padding: 3px 10px 2px;
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0
}


.progress {
  overflow: hidden;
  height: 20px !important;
  margin-bottom: 20px;
  background-color: #989393 !important;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1)
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px !important;
  color: #fff;
  text-align: center;
  background-color: #ff0026 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
}

.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 40px 40px
}

.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c !important;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
}

.progress-bar-info {
  background-color: #80c4e1 !important;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}

.progress-bar-warning {
  background-color: #f0ad4e !important;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
}

.progress-bar-danger {
  background-color: #f51d16 !important;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)
}


audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline
}

.dashboard-progress {
  background-color: rgba(25, 25, 25, 0.2);
}
.dashboard-circle-ok {
  color: green;
}
.dashboard-circle-danger {
  color: #ff0026;
}
.progress.dashboard-progress > .progress-type {
  background: none;
}

