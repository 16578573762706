/* BREAD CRUMBS */

.breadcrumb li+li:before {
  content: "\003e" !important;
  padding: 0 14px;
  color: inherit !important;
}

.breadcrumb li {
  text-transform: uppercase !important;
  font-weight: bold !important;
  color: #2d2d2d;
}

.breadcrumb li>a {
  text-decoration: none !important;
  color: #2d2d2d;
}

.breadcrumb li>a:hover {
  text-decoration: none !important;
  color: #ff0026;
}


.breadcrumb li:not(:last-child) {
  color: #2d2d2d;
}

.breadcrumb {
  background-color: inherit !important;
  font-size: 16px;
}
