/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "./stylesheets/breadcrumbs.css";
@import "./stylesheets/glyphicon.css";
@import "./stylesheets/progressbar.css";



html {
  overflow-y: scroll;
}

/* body {
  font-family: Helvetica Neue LT Pro, Arial, sans-serif;
  padding-top: 30px;
  color: #2d2d2d;
  font-family: Helvetica Neue LT Pro, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2d2d2d;
  background-color: #fff;
  margin: 0;

} */

html body {
  font-family: Helvetica Neue LT Pro, Arial, sans-serif;
  font-size: 14px !important;
  padding-top:0px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #2d2d2d;
  background-color:#f8f8f8;
  margin: 0;
}


* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: inherit;
}


/* ccrpcontainer along with border */
.ccrpcontainer {
  border: 0px solid #d9d9d9 !important;
  padding: 5px 0px 5px 0px !important;
  /* margin-right: auto !important;
  margin-left: auto !important; */
  width: 97% !important;
  margin: 0px auto;

}

@media (width < 425px) {
  .ccrpcontainer {
    border: none;
    margin: auto;
  }

  body {
    padding-top: 10px;
  }
}
@media (min-width:768px) {
  .ccrpcontainer {
    width: 750px;
  }
}

@media (min-width:992px) {
  .ccrpcontainer {
    width: 970px
  }
}

@media (min-width:1200px) {
  .ccrpcontainer {
    width: 1170px
  }
}

@media (max-width:767px) {
  .ccrpcontainer {
    padding: 5px 5px 5px 5px !important;
  }
  .removePadMobView {
    padding: 0px !important;
  }
}

/* Hitachi LOGO */
.abb-logo {
  /* margin-top: 19px; */
  margin-left: -37px;
  padding: 0px;
  background: url(./assets/images/hitachi-logo.png) no-repeat right;
  background-size: 145px 25px;
  width: 185px;
  height: 30px;
  display: inline-block;
}

/* CCRP LOGO */
.abb-ccrp {
  margin-left: 14px;
  vertical-align: super;
  font-size: 30px;
  font-weight: bold;
}

/*Energy LOGO*/
.energy-logo{
  /* margin-right: 10px; */
  padding: 0px;
  background: url(./assets/images/logo1.webp) no-repeat right;
  background-size: 150px 30px;
  width: 170px;
  height: 22.5px;
  display: inline-block;
}

.first-row{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

/* Footer */
.footer {
  font-size: smaller;
  margin-top: 30px;
  margin-bottom: 10px;
  bottom: 0;
}

/* User Profile Details */

.yammer-notific-profile {
  float: right;
  /* margin-right: 5px; */
}

.yammer-notific-profile span {
  margin: 3px;
}

.clear-white-space {
  border: 0;
  padding: 0;
  margin-top: 10px;
  background-color: white;
}

.clear-white-space-extra {
  border: 0;
}
.clear-white-space-border {
  border: 0;
  padding: 0;
}

/* notification */

.notification p {
  margin: 0.1rem;
}


.dashboard-status-label {
  vertical-align: middle !important;
}


/* small Font Size */

small {
  font-size: 80% !important;
}


button:focus,
input[type=text]:focus,
textarea:focus,
.form-control:focus,
.btn:focus,
select,
select:focus {
  outline: 0 !important;
  border-color: #bfbfbf;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
  color: #2d2d2d;
  background-color: #ebebeb;
  border-color: #ebebeb;
}


/* Tool Tip */


.tooltip-example [tooltip] {
  display: inline-block;
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
}
.test{
  margin:200px;
}
.ng-tooltip {
  position:absolute;
  /* font-size: xx-small; */
  text-align: left;
  color: #f8f8f2;
  padding: 5px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}


.dashboard-status-label {
  vertical-align: middle !important;
}


/* small Font Size */

small {
  font-size: 80% !important;
}


button:focus,
input[type=text]:focus,
textarea:focus,
.form-control:focus,
.btn:focus,
select,
select:focus {
  outline: 0 !important;
  border-color: #bfbfbf;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
  color: #2d2d2d;
  background-color: #ebebeb;
  border-color: #ebebeb;
}

/* Date picker */
.bs-datepicker.theme-default .bs-datepicker-head{
  background-color: white;
}
.bs-datepicker.theme-default .bs-datepicker-head button {
  color: #000;
}
.bs-datepicker.theme-default .bs-datepicker-body thead th{
  color: #000;
}


/* Tool Tip */


.tooltip-example [tooltip] {
  display: inline-block;
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #000;
  border-radius: 4px;
}
.test{
  margin:200px;
}
.ng-tooltip {
  position:absolute;
  font-size: xx-small;
  text-align: left;
  color: #f8f8f2;
  padding: 5px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}


/* button red color */
.ccrpbutton {
  background-color: rgb(255, 0, 38)	; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 95%;
  cursor: pointer;
  min-width:100px;
  max-width:100px;
  min-height:23px;
  max-height:23px;
  margin-left: 10px;
}

.selectBtn{
  min-width: 100px;
  max-width: 100px;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  background: no-repeat scroll 2px -38px #FF0026;
  color: #FFF;
  border: #FF0026;
  border: none;
  font-family: Helvetica Neue LT Pro,Arial,sans-serif;
  font-size: 14px;
  min-height: 20px;
  max-height: 30px;
  position: relative;
  align-items: center;
}

.widgetButtonMedium {
  min-width: 30%;
  max-width: auto;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  background: #ff0026;
  color: #fff;
  border: #ff0026;
  font-size: 14px;
  min-height: 20px;
  /* max-height: 20px; */
  position: relative;
  padding: 3px 8px 3px 8px !important;
  border-radius: 5px !important;
  text-transform: capitalize;
  border-radius: 0px !important;
  line-height: normal;
}
.inner-heading1 {
  font-size: 14px;
  margin: 10px 0px 10px 0px !important;
  font-weight: 600;
  background: #f1f1f1;
  padding: 5px 10px;
  text-align: left;
  width: 100%;
  text-transform: capitalize;
}
.summarize-text {
  margin-left: 5px;
}
@media (max-width: 769px) {
  .mobile-space1 {
    margin-bottom: 10px;
  }
  .remove-ps{padding-left: 0px !important;}
  .remove-pe{padding-right: 0px !important;}
  .remove-ms{margin-left: 0px !important;}
  .remove-mt{margin-top: 0px !important;}
  .remove-bt{margin-bottom: 0px !important;}
  .remove-px{padding-left:0px !important;padding-right:0px !important;}
  .widgetButtonMedium{font-size: 12px;}
  .abb-ccrp {
    font-size: 22px;
   }
}


.ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 0px;
  }
  .ngx-pagination .current {
  padding: 7px 14px !important;
  background: #ff0026 !important;
  color: #fefefe;
  cursor: pointer;
  }
  .ngx-pagination a, .ngx-pagination button
  {
    padding: 7px 14px !important;border-right: 1px solid #ccc;
    text-decoration: none;
  }

  li.pagination-next a {
    border: 0px !important;
    text-decoration: none;
  }
  .ngx-pagination li{
    margin-right: 0px !important;
  }
  .ngx-pagination .disabled {
    padding: 7px 14px !important;
    color: #2d2d2d;
    background-color: #fff !important;
    border-radius: 6px;
  }
  .ngx-pagination .pagination-next a:after, .ngx-pagination
  .pagination-next.disabled:after,.ngx-pagination .pagination-previous a:before,
  .ngx-pagination .pagination-previous.disabled:before {
    margin-left: 0px !important; margin-right: 0px !important;
  }
  .tooltip-toLeft .glyphicon {
    top: 3px !important;
    left: 2px !important;
  }
  .popover-body .glyphicon.glyphicon-envelope {
    top: 3px !important;
  }
  /* CSS added by Krishna - 29.03.2023 */
  .form-control {
    padding: 4px 11px !important;
    font-size: 14px;
  }
  .customtextfield-s {
    float: left;
    width: 40% !important;
  }
  .customtextfield-xs {
    float: left;
    width: 30% !important;
  }
  .customtextfield-m {
    float: left;
    width: 60% !important;
  }
  .customtextfield-l {
    float: left;
    width: 70% !important;
    margin-bottom: 5px;height:22px;
    margin-top: 5px;
  }
  .infoIcon {
    font-size: 1rem;
    margin-top: 9px;
  }

  /* .accordion label {
    text-transform: capitalize;
} */

:host ::ng-deep .p-component{
  font-family: Helvetica Neue LT Pro, Arial, sans-serif;
  font-size: 14px !important;
}
/* In File upload select Button */

.p-overlaypanel {
  min-width: 100px !important;
  max-width: 300px !important;
  color: #FFF !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  background: no-repeat scroll 2px -38px #FF0026 !important;
  border: #FF0026 !important;
  font-family: Helvetica Neue LT Pro, Arial, sans-serif !important;
  border: none !important;
  font-size: 1em !important;
  min-height: 20px !important;
  max-height: 30px !important;
  position: relative !important;
  /* align-items: center !important; */
  border-radius: 0px !important;
}
/* In File upload remove button*/
.p-overlaypanel1 {
  background: no-repeat scroll 2px -38px #FF0026 !important;
  border: #FF0026 !important;
  color: #FFF !important;
  border-radius: 0px !important;
}
/* .disabled, .resolvingUnitDisabled,.customerContactNameClass{background-color: #d9d9d9 !important;}
.grayButton {
  background-color: Gray !important;
} */

.disabled, .resolvingUnitDisabled{background-color: #d9d9d9 !important; opacity: 0.6 !important;}
.grayButton {
  background-color: Gray !important;
}

.custom-textarea-auto {
  overflow-y: auto !important;
  height: 40px !important;
}
.custom-textarea-auto:focus {
  border-color: unset !important;
  box-shadow: none !important;
}
.custom-textarea-auto:hover {
  border: 1px solid #ced4da !important;
}
p-calendar .p-datepicker {
  min-width: auto !important;
}
p-calendar .p-calendar button.p-button-icon-only {
  height: 24px;
  background: transparent;
  color: #000;
  border: 1px solid #c6c6c6;
}
p-calendar.target-class .p-calendar input {
  height: 25px !important;
}
p-calendar .p-datepicker td span , p-calendar .p-datepicker td a {
  padding: 0;
  width: 20px !important;
  height: 20px !important;
  font-size: 14px;
}
p-calendar input.p-inputtext {
  padding: 0 10px !important;
  color: #212529;
  margin-left: 2px !important;
  padding-right: 0px !important;
}

p-calendar .p-datepicker td {
  padding: 0.3rem !important;
}
p-calendar .p-datepicker .p-datepicker-header {
  padding: 0 !important;
}
p-fileupload .p-fileupload .p-fileupload-content {
  padding: 0;
  border: none;
  width: 90%;
}
p-fileupload .p-fileupload .p-fileupload-content .p-fileupload-files {
  border: 1px solid #c6c6c6;
  padding: 5px 10px;border-radius: 5px;
}
p-fileupload .p-fileupload .p-fileupload-buttonbar {
  padding: 0;
  border: 0px;
  background: none;
  width: 40%;
}

p-fileupload .p-fileupload .p-fileupload-buttonbar span.p-button:focus {
  box-shadow: none;
  outline: none;
  outline-offset: unset !important;
}
p-fileupload .p-fileupload .p-fileupload-buttonbar span.p-button.p-focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: unset !important;
}

p-fileupload .p-fileupload .p-fileupload-buttonbar span.p-button {
  background-color: #ff0000;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 95%;
  cursor: pointer;
  min-width: 90px !important;
  height: 22px;
  line-height: normal;
  max-width: 100px;
  padding: 2px 8px 3px 8px;
  border-radius: 5px !important;
}
p-fileupload .p-fileupload .p-fileupload-buttonbar span.p-button .p-button-label {
  font-weight: 500;
  background-color: #ff0000;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 94%;
  cursor: pointer;
  min-width: 90px;
  height: 22px;
  line-height: normal;
  max-width: 100px;
  padding: 2px 8px 3px 8px;
  border-radius: 0px;
}
p-fileupload .p-fileupload {
  display: flex;
}
p-fileupload .p-fileupload .p-fileupload-buttonbar span.p-button .p-button-icon-left {
  margin-right: 0 !important;
}
.selectfile {
  margin-top: 3px !important;
}
p-fileupload .p-fileupload p-progressbar .p-progressbar {
  display: none;
}
.attachButton {
  min-width: 30px !important;
}
/* Text area height css*/
.textarea-height2{min-height: 75px !important;}

.asteriskRequired:after {
  content: "*";
  color: red;
  font-size: 20px !important;
  vertical-align: -webkit-baseline-middle !important;
  padding-left: 2px;
  line-height: 0;
  /* position: absolute; */
  /* padding-left: 3px; */
}
.bg-white1{background-color: #fff !important;}
.widgetButtoSmall,.widgetButtonSmall,.widgetButtonMedium{border-radius: 5px !important;}
.panel-space{padding: 5px 10px !important;font-weight:600;}
.widgetButtonSmall2 {
  max-width: 180px;min-width:100px;
  padding: 2px 8px 3px 8px !important;
  height: 22px;
  line-height: normal;
  border: 1px solid #ccc;border-radius: 5px;
}

.searchbuttoninnavbar {
  color: #fff !important;
  background-color: #ff0026 !important;
  border-color: #ff0026 !important;
  border-radius: 3px !important;
  height: 30px;
  width: 35px;
  padding: 3px !important;
  margin-left: -24px;
  font-size: .800rem !important;
}

.searchbuttoninnavbar:hover {
  color: #fff !important;
  background-color: #7E000A !important;
  border-color: #7E000A !important;
}

.label-align-right{
  float: right !important;
}

@media (width < 600px) {
  .label-align-right{
    float: left !important;
  }
}

.label-bold{
  font-weight: 600;
}

.icon-sort-btn {
  background-color: #eeeeee;
  margin-left: -1px !important;
}


.btn-default-borderless{
  border: none !important;
}



.widgetButtoMediumView {
  background: #ff0000;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 95%;
  cursor: pointer;
  min-width: 120px;
  max-width: 120px;
  height: 25px;
  border-radius: 5px;
  margin-bottom: 3px;
  margin-right: 1px;
  border-radius: 5px;
  line-height: normal;

}

.widgetButtoMediumView:hover {
  background: no-repeat scroll 2px -38px#7E000A;
}



.widgetButtoMediumView {
  background: #ff0000;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 95%;
  cursor: pointer;
  min-width: 120px;
  max-width: 120px;
  height: 25px;
  border-radius: 5px;
  margin-bottom: 3px;
  margin-right: 1px;
  border-radius: 5px;
  line-height: normal;

}

.widgetButtoMediumView:hover {
  background: no-repeat scroll 2px -38px#7E000A;
}


.widgetButtonLarge {
  min-width: 90px;
  max-width: 160px;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  background: #ff0026;
  color: #fff;
  border: #ff0026;
  font-size: 14px;
  min-height: 20px;
  max-height: 30px;
  position: relative;
  padding-top: 1px;
  border-radius: 5px;
  /* vertical-align: middle; */
}


.div-align-items-right{
  display:flex;
  justify-content:flex-end;
}

.div-align-items-center{
  display:flex;
  justify-content:center;
}
.tab-content-header{
  font-size: 16px;
  font-weight: bold;
}

 /* Pagination */
 .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 10px 0;
  border-radius: 4px
}

.pagination>li {
  display: inline
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2d2d2d;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  cursor: pointer;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
  color: #ff0026;
  background-color: #eee;
  border-color: #ddd
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  z-index: 2;
  color: #fff;
  background-color: #ff0026;
  border-color: #ff0026;
  cursor: default
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
  color: #999;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px
}
::ng-deep .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input
{background-color: transparent;box-shadow: none;}

.form-control:focus, .form-control:hover {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none !important;
  border-color: #bfbfbf !important;
}

.p-tooltip-text {
	font: 12px sans-serif;
}

.p-tooltip .p-tooltip-text {
	background-color: #FFF !important;
	color: #000 !important;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.toolTipClass .p-tooltip-arrow {
  visibility: hidden;
}
.p-tooltip-arrow {
 visibility: hidden !important;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #212529 !important;box-shadow: none;}
.p-autocomplete-multiple-container:not(.p-disabled).p-focus{box-shadow: none !important;border-color: #212529 !important}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):focus{box-shadow: none !important;}
.p-datatable .p-datatable-tbody > tr.p-highlight{color:initial !important}
.p-inputtext:enabled:focus{box-shadow: none !important;color: #212529 !important;border-color: #212529 !important;}
.complaintOther .disabled + input[type="hidden"] + button {
  background-color: gray !important;
  pointer-events: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:focus {
  outline: none;
  box-shadow: none;
}
.complaintOther .disabled + button {
  background-color: gray !important;
  pointer-events: none;
}
.complaintOther .resolutionCategory[disabled] + p-toast + button {
  background-color: gray !important;
  pointer-events: none;
}
.complaintOther .resolutionCategory[disabled] + button {
  background-color: gray !important;
  pointer-events: none;
}
.complaintOther .productSection[disabled] + input[type="hidden"] + button,
.complaintOther .resolvingUnitDisabled[disabled] + input[type="hidden"] + button,
.complaintOther .productSection[disabled] + button, .fillInControl button {
  background-color: gray !important;
  pointer-events: none;
}

::ng-deep .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #ccc;
  border-color: #333;
  color: #000;
}
::ng-deep .p-link:focus{box-shadow: none;}
::ng-deep .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 35px;
  font-size: 14px;
  height: 35px;
  font-weight: 600;
}

::ng-deep .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{box-shadow: none !important;}

@media only screen and (max-width:1023px) and (min-width: 280px){
  html body , .dashboard-item-data-label, .form-control{font-size: 12px !important;}
  .inner-heading-wrapper{--bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }
  .widgetButtonMedium{font-size: 12px;}
}

@media only screen and (max-width:767px) and (min-width: 280px){

  .system-timeout-modal {
    width: 100% !important;
}
.widgetButtoSmall , .modal-body{font-size:12px}
::ng-deep p-dropdown .p-component .p-inputtext, .inner-heading1{font-size: 12px;}
.inner-heading-wrapper{--bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));}
  .summarize-text textarea{width: 100% !important;}
  .summarize-text{padding-left: 0px !important;padding-right: 0px !important;margin-left: 0px;}
.add-mt-smalldevice{margin-top: 8px !important;}
::ng-deep .p-toast {width:15rem !important;}
.choose-role-pop-up{width: 85% !important; height:auto !important;}
}


@media only screen and (max-width:1023px) and (min-width:768px){
  .choose-role-pop-up{width: 85% !important; height:auto !important;}
}

.inputFieldDisabled{
  background-color: #d9d9d9 !important;
  opacity: 0.6 !important;
}

::ng-deep .form-control:disabled {
    background-color: #d9d9d9 !important;
    opacity: 0.6 !important;
}

::ng-deep .p-component:disabled {
  opacity: 0.6 !important;
  background-color: #d9d9d9 !important;
}

.opacity-primary{
  opacity: 1 !important;
}
.opacity-secondary{
  opacity: 0.6 !important;
}

@media only screen and (max-width:1400px) and (min-width: 1366px){
  .choose-role-pop-up{height: 27% !important;}

}

@media (min-width:2000px) and (max-width: 2600px){
  .choose-role-pop-up{height: 18% !important;}
}
